const FRIEND_PAGE_PATH = "/friends";

const Friend = () => {
    return (
        <>
            <h1>This is Friends Page</h1>
        </>
    );
}

export {Friend, FRIEND_PAGE_PATH};